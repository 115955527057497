import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { BrowserRouter } from "react-router-dom";

import variables from "styles/variables";
import { userService } from "services";
import { GuiState } from "states/gui.state";
import Content from "components/content";

import Header from "components/header/header";

const styles = createUseStyles({
    app: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        overflowX: "hidden",
        overflowY: "auto",
        flexDirection: "column",
        alignItems: "normal",
        justifyContent: "start",

        background: "black",
        backgroundImage: `url("/background.png")`,
        color: variables.colors.foreground,
        textShadow: `2px 2px #000000AA`,
    },
    bob: {
        animation: "$bob-animation",
        animationDuration: 100000,
        animationIterationCount: "infinite",
        animationTimingFunction:  "ease-in-out",
        animationDirection:  "alternate",
        animationFillMode: "both",
    },
    "@keyframes bob-animation": {
        "0%": {
            backgroundPositionX: 0,
            backgroundPositionY: 0,
        },
        "50%": {
            backgroundPositionX: "200vh",
            backgroundPositionY: "100vh",
        },
        "100%": {
            backgroundPositionX: "-200vh",
            backgroundPositionY: "200vh",
        },
    },
});

export let setGui = (newGuiState: GuiState, clear: boolean = false) => {};

export default function App(): JSX.Element
{
    // States:
    const [userState, setUserState] = useState(userService.getUserState());
    useEffect(() => userService.addListener(() => {
        setUserState(userService.getUserState());
    }));
    const [guiState, setGuiState] = useState({} as GuiState);

    // Set GUI:
    setGui = (newGuiState: GuiState, clear: boolean = false) => {
        if (clear) {
            setGuiState(newGuiState);
            return;
        }
        setGuiState({
            ...guiState,
            ...newGuiState
        });
    };

    // Component:
    const classes = styles();
    return (
        <div className={`${classes.app} ${classes.bob}`}>
            <BrowserRouter>
                <Header user={userState} gui={guiState} />
                <Content user={userState} gui={guiState} />
            </BrowserRouter>
        </div>
    );
}