import { ChangeEvent, useEffect, useState } from "react";

import { setGui } from "components/app";
import { loginService } from "services";
import widgets from "styles/widgets";

export default function Login(): JSX.Element
{
    // State:
    const [state, setState] = useState({
        view: "login",
        username: localStorage.getItem("username") ?? "",
        email: "",
        given_name: "",
        family_name: "",
        password: "",
        confirm: "",
        code: "",
        error: "",
    });

    // Set View
    const setView = (view: string, error?: string) => {
        setState({
            ...state,
            view,
            error: error ?? "",
        });
    };

    // Event Listeners:
    useEffect(() => {
        // Login:
        const removeLoginListener = loginService.addListener((event, data) => setGui({}, true));

        // Error Handling:
        const removeErrorListener = loginService.addErrorListener((event, data) => {
            switch (event) {
                case "login.error":
                    setView("login_error", data.message);
                    break;
                case "register.error":
                    setView("register_error", data.message);
                    break;
                case "password.reset.error":
                    setView("reset_password_error", data.message);
                    break;
            }
        });
        const removePasswordResetListener = loginService.addPasswordResetListener((event, data) => {
            switch (event) {
                case "password.reset.success":
                    setView("reset_password_success");
                    break;
            }
        });
        return () => {
            removeLoginListener();
            removeErrorListener();
            removePasswordResetListener();
        }
    });

    // Input Handling:
    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.id]: event.target.value,
        });
    };

    // Actions:
    const login = () => {
        setView("logging_in");
        loginService.login({
            grant_type: "password",
            client_id: process.env.REACT_APP_ACCOUNTS_ID ?? "",
            client_secret: process.env.REACT_APP_ACCOUNTS_SECRET ?? "",
            username: state.username,
            password: state.password,
        });
    };

    const register = () => {
        setView("registering");
        loginService.register({
            username: state.username,
            given_name: state.given_name,
            family_name: state.family_name,
            primary_email: state.email,
            emails: [state.email],
            password: state.password,
            password_confirm: state.confirm,
        });
    };

    const forgotPassword = () => {
        setView("reset_password");
        loginService.forgotPassword({
            email: state.email,
        });
    };

    const resetPassword = () => {
        setView("reset_password_sent");
        loginService.resetPassword({
            email: state.email,
            code: state.code,
            password: state.password,
            password_confirm: state.confirm,
        });
    };

    // Component:
    const widgetClasses = widgets();
    let component;
    switch (state.view) {
        case "logging_in":
            component = (<>
                <h1>Logging In</h1>
                <p>Summoning soul from a nearest schism rift...</p>
            </>);
            break;

        case "login_error":
            component = (<>
                <h1>Loggin Error</h1>
                <p>An error occurred while logging in: {state.error}</p>

                <div className={widgetClasses.row}>
                    <button onClick={() => setView("login")}>
                        Retry
                    </button>
                </div>
            </>);
            break;

        case "registering":
            component = (<>
                <h1>Registering</h1>
                <p>Creating new user account...</p>
            </>);
            break;

        case "forgot_password":
            component = (<>
                <h1>Forgot Password</h1>
                <p>You can request an email to be sent with a Password Reset Code you can use to change your password.</p>

                <div className={widgetClasses.row}>
                    <label>Email</label>
                    <input id="email" value={state.email} onChange={handleInput} />
                </div>

                <div className={widgetClasses.row}>
                    <button className={widgetClasses.confirm} onClick={forgotPassword} disabled={!state.email}>
                        Send Reset Email
                    </button>
                    <button onClick={() => setView("reset_password")}>
                        Enter Code
                    </button>
                    <button className={widgetClasses.warning} onClick={() => setView("login")}>
                        Cancel
                    </button>
                </div>
            </>);
            break;

        case "reset_password":
            component = (<>
                <h1>Password Reset</h1>
                <p>If an account exists, an email has been sent with a Password Reset Code, please enter that below along with a new password.</p>

                <div className={widgetClasses.row}>
                    <label>Email</label>
                    <input id="email" value={state.email} onChange={handleInput} />
                </div>

                <div className={widgetClasses.row}>
                    <label>Code</label>
                    <input id="code" value={state.code} onChange={handleInput} />
                </div>

                <div className={widgetClasses.row}>
                    <label>Password</label>
                    <input id="password" value={state.password} onChange={handleInput} type="password" />
                </div>

                <div className={widgetClasses.row}>
                    <label>Confirm</label>
                    <input id="confirm" value={state.confirm} onChange={handleInput} type="password" />
                </div>

                <div className={widgetClasses.row}>
                    <button className={widgetClasses.confirm} onClick={resetPassword}
                        disabled={!state.email || !state.code || !state.password || state.password !== state.confirm}
                    >
                        Reset Password
                    </button>
                    <button className={widgetClasses.warning} onClick={() => setView("login")}>
                        Cancel
                    </button>
                </div>
            </>);
            break;

        case "reset_password_sent":
            component = (<>
                <h1>Password Reset Sent</h1>
                <p>Resetting password...</p>
            </>);
            break;

        case "reset_password_error":
            component = (<>
                <h1>Password Reset Error</h1>
                <p>Error resetting password: {state.error}</p>

                <div className={widgetClasses.row}>
                    <button onClick={() => setView("reset_password")}>
                        Retry
                    </button>
                </div>
            </>);
            break;

        case "reset_password_success":
            component = (<>
                <h1>Password Reset Complete</h1>
                <p>Password reset successfully! Please try to login again.</p>

                <div className={widgetClasses.row}>
                    <button onClick={() => setView("login")}>
                        Login
                    </button>
                </div>
            </>);
            break;

        case "register":
            component = (<>
                <h1>Register</h1>

                <div className={widgetClasses.row}>
                    <label>Username</label>
                    <input id="username" value={state.username} onChange={handleInput} />
                </div>

                <div className={widgetClasses.row}>
                    <label>Given Name</label>
                    <input id="given_name" value={state.given_name} onChange={handleInput} />
                </div>

                <div className={widgetClasses.row}>
                    <label>Family Name</label>
                    <input id="family_name" value={state.family_name} onChange={handleInput} />
                </div>

                <div className={widgetClasses.row}>
                    <label>Email</label>
                    <input id="email" value={state.email} onChange={handleInput} />
                </div>

                <div className={widgetClasses.row}>
                    <label>Password</label>
                    <input id="password" value={state.password} onChange={handleInput} type="password" />
                </div>

                <div className={widgetClasses.row}>
                    <label>Confirm</label>
                    <input id="confirm" value={state.confirm} onChange={handleInput} type="password" />
                </div>

                <div className={widgetClasses.row}>
                    <button className={widgetClasses.confirm} onClick={register}
                        disabled={!state.username || !state.email || !state.password || state.password !== state.confirm}
                    >
                        Register
                    </button>
                    <button className={widgetClasses.warning} onClick={() => setView("login")}>
                        Cancel
                    </button>
                </div>
            </>);
            break;

        case "register_error":
            component = (<>
                <h1>Register Error</h1>
                <p>An error occurred while registering: {state.error}</p>

                <div className={widgetClasses.row}>
                    <button onClick={() => setView("register")}>
                        Retry
                    </button>
                </div>
            </>);
            break;

        default:
            component = (<>
                <div className={widgetClasses.row}>
                    <h1>Login</h1>
                </div>

                <div className={widgetClasses.row}>
                    <label>Username</label>
                    <input id="username" value={state.username} onChange={handleInput} />
                </div>

                <div className={widgetClasses.row}>
                    <label>Password</label>
                    <input id="password" value={state.password} onChange={handleInput} type="password" />
                </div>

                <div className={widgetClasses.row}>
                    <button className={widgetClasses.confirm} onClick={login}
                        disabled={!state.username || !state.password}
                    >
                        Login
                    </button>
                </div>

                <div className={widgetClasses.row}>
                    <button className={widgetClasses.info} onClick={() => setView("register")}>
                        Register
                    </button>
                    <button onClick={() => setView("forgot_password")}>
                        Forgot Password
                    </button>
                    <button className={widgetClasses.warning} onClick={() => setGui({}, true)}>
                        Close
                    </button>
                </div>
            </>);
    }

    return (
        <div className={`${widgetClasses.overlay}`}>
            <div className={`${widgetClasses.popup} ${widgetClasses.form}`}>
                {component}
            </div>
        </div>
    );
}