import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Navigate, Route, Routes } from "react-router-dom";

import { accountsGateway } from "services";
import { UserState } from "states/user.state";
import { GuiState } from "states/gui.state";

import Home from "components/pages/home";
import Register from "components/pages/register";

const styles = createUseStyles({
    content: {
        position: "relative",
        flexGrow: 1,
    },
});

export default function Content(props: {
    user: UserState,
    gui: GuiState,
}): JSX.Element
{
    // Component:
    const classes = styles();
    return (
        <div className={`${classes.content}`}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/register" element={props.user.dto ? <Navigate to="/" /> : <Register />} />
            </Routes>
            {props.gui.popup}
        </div>
    );
}