import { Socket } from "socket.io-client"

import { AbstractService } from "services/abstract.service";
import { ErrorDto } from "dtos/error.dto";
import { LoginDto, LoginRequestDto } from "dtos/login.dto";
import { RegisterRequestDto } from "dtos/register.dto";
import { PasswordForgotRequestDto } from "dtos/password-forgot.dto";
import { PasswordResetRequestDto } from "dtos/password-reset.dto";
import { accountsGateway } from "services";

export class LoginService extends AbstractService<LoginDto>
{
    public errorListeners: ((event: string, data: any) => void)[] = [];
    public passwordResetListeners: ((event: string, data: any) => void)[] = [];

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket);

        // Connection (Auto Login):
        this.socket.on("connect", () => {
            const username: string | null = localStorage.getItem("username");
            const refreshToken: string | null = localStorage.getItem("refresh");
            if (username && refreshToken) {
                console.log(`[Login Service] Auto login as ${username} with refresh token...`);
                this.login({
                    grant_type: "refresh_token",
                    client_id: process.env.REACT_APP_ACCOUNTS_ID ?? "",
                    client_secret: process.env.REACT_APP_ACCOUNTS_SECRET ?? "",
                    username,
                    refresh_token: refreshToken,
                });
            }
        });

        // Login:
        this.socket.on('login.success', (dto: LoginDto) => {
            console.log("[Login Service] Login successful.");
            if (dto.refresh_token) {
                localStorage.setItem("username", dto.user.username);
                localStorage.setItem("refresh", dto.refresh_token);
            } else {
                localStorage.removeItem("refresh");
            }
            this.listeners.forEach(listener => listener('login.success', dto));
        });

        this.socket.on('register.success', (dto: LoginDto) => {
            console.log("[Login Service] Registeration successful.");
            if (dto.refresh_token) {
                localStorage.setItem("username", dto.user.username);
                localStorage.setItem("refresh", dto.refresh_token);
            } else {
                localStorage.removeItem("refresh");
            }
            this.listeners.forEach(listener => listener('register.success', dto));
        });

        // Password Reset:
        this.socket.on('password.reset.success', (dto: any) => {
            console.log("[Login Service] Password reset complete.");
            this.passwordResetListeners.forEach(listener => listener('password.reset.success', dto));
        });

        // Errors:
        this.socket.on('login.error', (dto: ErrorDto) => {
            console.warn(`[Login Service] Login error: ${dto.message}`);
            localStorage.removeItem("refresh");
            this.errorListeners.forEach(listener => listener('login.error', dto));
        });

        this.socket.on('register.error', (dto: ErrorDto) => {
            console.warn("[Login Service] Registeration error.");
            this.errorListeners.forEach(listener => listener('register.error', dto));
        });

        this.socket.on('password.reset.error', (dto: ErrorDto) => {
            console.warn("[Login Service] Password reset error.");
            this.errorListeners.forEach(listener => listener('password.reset.error', dto));
        });
    }

    /**
     * Adds an error event listener.
     * @param listener The event listener callback function to call.
     * @return Returns a callback to remove the event listener.
     */
    public addErrorListener(listener: (event: string, data: any) => void): () => void
    {
        this.errorListeners.push(listener);
        return () => this.errorListeners = this.errorListeners.filter(existingListener => existingListener !== listener);
    }

    /**
     * Adds a password reset event listener.
     * @param listener The event listener callback function to call.
     * @return Returns a callback to remove the event listener.
     */
    public addPasswordResetListener(listener: (event: string, data: any) => void): () => void
    {
        this.passwordResetListeners.push(listener);
        return () => this.passwordResetListeners = this.passwordResetListeners.filter(existingListener => existingListener !== listener);
    }

    /**
     * Sends a login message.
     * @param dto The message dto to send.
     */
    public login(dto: LoginRequestDto): void
    {
        console.log("[Login Service] Logging in...");
        this.socket.emit('login', dto);
    }

    /**
     * Sends a logout message.
     */
    public logout(): void
    {
        console.log("[Login Service] Logging out...");
        localStorage.removeItem("refresh");
        this.socket.emit('logout', {});
        accountsGateway.disconnect();
    }

    /**
     * Sends a register message.
     * @param dto The message dto to send.
     */
    public register(dto: RegisterRequestDto): void
    {
        console.log("[Login Service] Registering...");
        this.socket.emit('register', dto);
    }

    /**
     * Sends a forgot password message.
     * @param dto The message dto to send.
     */
    public forgotPassword(dto: PasswordForgotRequestDto): void
    {
        console.log("[Login Service] Sending forgot password request...");
        this.socket.emit('password.forgot', dto);
    }

    /**
     * Sends a reset password message.
     * @param dto The message dto to send.
     */
    public resetPassword(dto: PasswordResetRequestDto): void
    {
        console.log("[Login Service] Sending password reset request...");
        this.socket.emit('password.reset', dto);
    }
}