import axios, { AxiosInstance } from "axios";
import { Socket } from "socket.io-client"

export abstract class AbstractService<DtoType>
{
    public listeners: ((event: string, data: DtoType) => void)[] = [];
    public readonly socket: Socket;
    public readonly api: AxiosInstance;

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        this.socket = socket;
        this.api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL + "/api/v1" ?? "",
        });
    }

    /**
     * Adds an event listener.
     * @param listener The event listener callback function to call.
     * @return Returns a callback to remove the event listener.
     */
    public addListener(listener: (event: string, data: DtoType) => void): () => void
    {
        this.listeners.push(listener);
        return () => this.listeners = this.listeners.filter(existingListener => existingListener != listener);
    }
}