import { createUseStyles } from "react-jss";

import widgets from "styles/widgets";

const styles = createUseStyles<any, any>({
    project: {
        width: 256,
        height: 128,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    shardTCG: {
        backgroundImage: `url("/shard_tcg.png")`,
    },
    lycanitesMobs: {
        backgroundImage: `url("/lycanites_mobs.png")`,
    },
    digimonGemini: {
        backgroundImage: `url("/digimon_gemini.png")`,
    },
});

export default function Home(): JSX.Element
{
    // Component:
    const classes = styles();
    const widgetClasses = widgets();
    return (
        <div className={`${widgetClasses.page}`}>
            <div className={`${widgetClasses.panel}`}>
                <h1>Nephrite Portal</h1>
                <p>Welcome to the Nephrite Portal, here you may manage your Nephrite Account. You may use this account to access various Nephrite Projects as well as connect third party accounts.</p>
                <p>This portal is still under development and more management features will be added over time.</p>
            </div>
            <div className={`${widgetClasses.panel}`}>
                <h2>Nephrite Projects</h2>
                <div className={`${widgetClasses.row}`}>
                    <a className={`${classes.project} ${classes.shardTCG}`} title="Shard TCG" href="https://shardtcg.com"></a>
                    <a className={`${classes.project} ${classes.lycanitesMobs}`} title="Lycanite's Mobs" href="https://lycanitesmobs.com"></a>
                    <a className={`${classes.project} ${classes.digimonGemini}`} title="Digimon Gemini" href="https://client.digimon-gemini.com"></a>
                </div>
            </div>
        </div>
    );
}