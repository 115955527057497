import { createUseStyles } from "react-jss";

import { loginService, userService } from "services";
import variables from "styles/variables";
import widgets from "styles/widgets";

import { setGui } from "components/app";
import { UserState } from "states/user.state";
import { GuiState } from "states/gui.state";
import { Link } from "react-router-dom";
import Login from "components/popups/login";


const styles = createUseStyles<any, any>({
    container: {
        padding: "2em 4em",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",

        backgroundColor: variables.colors.positive + "AA",

        "& h1, & h2, & h3": {
            margin: 0,
        }
    },
    group: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "1em",
    },
    logo: {
        width: "4em",
        height: "4em",
        margin: "-1em 0 0 0",
        backgroundImage: "url(crystal.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
    },
});

export default function Header(props: {
    user: UserState,
    gui: GuiState,
}): JSX.Element
{
    // Actions:
    const loginToggle = () => {
        if (props.gui.popupName === "login") {
            setGui({}, true);
            return;
        }
        setGui({
            popup: (<Login />),
            popupName: "login",
        }, true);
    };

    const register = () => {
        loginService.logout();
        userService.clearUser();
    };

    const logout = () => {
        loginService.logout();
        userService.clearUser();
    };

    // Styles:
    const classes = styles();
    const widgetClasses = widgets();

    // User Group:
    let userGroup: JSX.Element = (<div className={`${classes.group}`}>
        <h3>Guest</h3>
        <button className={`${widgetClasses.info}`} onClick={loginToggle}>Login</button>
        <Link to="/register">
            <button className={`${widgetClasses.confirm}`}>Register</button>
        </Link>
    </div>);
    if (props.user.dto) {
        userGroup = (<div className={`${classes.group}`}>
            <h3>{props.user.dto.username}</h3>
            <button className={`${widgetClasses.warning}`} onClick={logout}>Logout</button>
        </div>);
    }

    // Component:
    return (
        <nav className={`${classes.container}`}>
            <div className={`${classes.group}`}>
                <div title="Nephrite Logo" className={`${classes.logo}`} />
                <h1>Nephrite</h1>
            </div>
            {userGroup}
        </nav>
    );
}