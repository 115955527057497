import { createUseStyles } from "react-jss";

import variables from "styles/variables";

export default createUseStyles({
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        gap: "2em",
        flexBasis: 0,
        flexGrow: 1,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        gap: "2em",
        flexBasis: 0,
        flexGrow: 1,
    },
    grid: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        height: "40vh",
        overflowY: "scroll",
        gap: "2em",
    },
    page: {
        width: "auto",
        height: "auto",
        padding: "2em 4em",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    panel: {
        width: "100%",
        padding: "2em",
        marginTop: "2em",
        borderRadius: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: variables.colors.background + "AA",
    },
    overlay: {
        position: "absolute",
        zindex: 100,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: "#000000CC",
    },
    popup: {
        position: "absolute",
        left: "50%",
        top: "8em",
        transform: "translateX(-50%)",
        padding: "2em",
        borderRadius: 16,
        maxWidth: "60%",
        background: variables.colors.background + "AA",
        "& h1": {
            textAlign: "center",
        },
    },
    form: {
        display: "flex",
        gap: "1em",
        flexDirection: "column",
        "& label": {
            marginLeft: "2em",
            flexGrow: 1,
            textAlign: "left",
        },
        "& input": {
            width: "55%",
            marginLeft: "2em",
        },
        "& input[type=checkbox]": {
            width: "auto",
            transform:" scale(2)",
        },
        "& > div button": {
            flexGrow: 1,
            marginLeft: 4,
            marginRight: 4,
        },
    },
    checkboxContainer: {
        width: "55%",
        marginLeft: "2em",
        flexGrow: 1,
    },
    confirm: {
        backgroundColor: variables.colors.positive,
    },
    info: {
        backgroundColor: variables.colors.primary,
    },
    warning: {
        backgroundColor: variables.colors.negative,
    },

    "@media screen and (max-width: 720px)": {
        column: {
            paddingBottom: 8,
            gap: 8,
        },
        row: {
            paddingBottom: 8,
            gap: 8,
            flexWrap: "wrap",
            justifyContent: "center",
        },
        grid: {
            paddingBottom: 8,
            gap: 8,
        },
        popup: {
            width: "80%",
        },
        form: {
            gap: 8,
            "& label": {
                marginLeft: 0,
                flexGrow: 1,
            },
            "& input": {
                flexGrow: 1,
                marginLeft: 0,
            },
            "& > div button": {
                flexGrow: 1,
                marginLeft: 4,
                marginRight: 4,
            },
            "& > div label": {
                textAlign: "center",
            },
        },
    },

    "@media screen and (min-width: 1280px)": {
        popup: {
            width: "40%",
        },
    },
});