import { ErrorDto } from "dtos/error.dto";
import { ChangeEvent, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { loginService } from "services";

import widgets from "styles/widgets";

const styles = createUseStyles<any, any>({
    project: {
        width: 256,
        height: 128,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    shardTCG: {
        backgroundImage: `url("/shard_tcg.png")`,
    },
    lycanitesMobs: {
        backgroundImage: `url("/lycanites_mobs.png")`,
    },
    digimonGemini: {
        backgroundImage: `url("/digimon_gemini.png")`,
    },
});

export default function Register(): JSX.Element
{
    // State:
    const [state, setState] = useState({
        pending: false,
        error: "",
        username: localStorage.getItem("username") ?? "",
        email: "",
        given_name: "",
        family_name: "",
        password: "",
        confirm: "",
        code: "",
    });

    // Input Handling:
    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.id]: event.target.value,
        });
    };

    // Actions:
    const register = () => {
        setState({
            ...state,
            pending: true,
            error: "",
        });
        loginService.register({
            username: state.username,
            primary_email: state.email,
            emails: [state.email],
            given_name: state.given_name,
            family_name: state.family_name,
            password: state.password,
            password_confirm: state.confirm,
        });
    };

    // Event Listeners:
    useEffect(() => {
        const removeErrorListener = loginService.addErrorListener((event: string, error: ErrorDto) => {
            setState({
                ...state,
                pending: true,
                error: error.message,
            });
        });
        return () => {
            removeErrorListener();
        }
    });

    // Components:
    const widgetClasses = widgets();
    const registerComp = (
        <div className={`${widgetClasses.page}`}>
            <div className={`${widgetClasses.panel}`}>
                <h1>Register Your Nephrite Account</h1>
                <div className={widgetClasses.row}>
                    <div className={`${widgetClasses.column} ${widgetClasses.form}`}>
                        <div className={widgetClasses.row}>
                            <label>Username</label>
                            <input id="username" value={state.username} onChange={handleInput} />
                        </div>

                        <div className={widgetClasses.row}>
                            <label>Email</label>
                            <input id="email" value={state.email} onChange={handleInput} />
                        </div>

                        <div className={widgetClasses.row}>
                            <label>Given Name</label>
                            <input id="given_name" value={state.given_name} onChange={handleInput} />
                        </div>

                        <div className={widgetClasses.row}>
                            <label>Family Name</label>
                            <input id="family_name" value={state.family_name} onChange={handleInput} />
                        </div>

                        <div className={widgetClasses.row}>
                            <label>Password</label>
                            <input id="password" value={state.password} onChange={handleInput} type="password" />
                        </div>

                        <div className={widgetClasses.row}>
                            <label>Confirm</label>
                            <input id="confirm" value={state.confirm} onChange={handleInput} type="password" />
                        </div>

                        <div className={widgetClasses.row}>
                            <button className={widgetClasses.confirm} onClick={register}
                                disabled={!state.username || !state.email || !state.password || state.password !== state.confirm}
                            >
                                Register
                            </button>
                        </div>
                    </div>
                    <div className={widgetClasses.column}>
                        <div className={widgetClasses.row}>
                            <p>A Nephrite Account can be used to sign in to various projects including Shard TCG. The email address you use will only be used for password reset, verifications and game notifications if you choose to enable them.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const errorComp = (
        <div className={`${widgetClasses.page}`}>
            <div className={`${widgetClasses.panel}`}>
                <h1>Error</h1>
                <p>A problem occured whilst registering your account, please check that all details are entered correctly:</p>
                <p>{state.error}</p>
                <button onClick={() => setState({ ...state, error: "" })}>
                    Retry
                </button>
             </div>
        </div>
    );

    return state.error ? errorComp : registerComp;
}