import { Socket } from "socket.io-client"

import { AbstractService } from "services/abstract.service";
import { UserState } from "states/user.state";
import { UserDto } from "dtos/user.dto";
import { LoginDto } from "dtos/login.dto";

export class UserService extends AbstractService<UserDto | undefined>
{
    public userDto?: UserDto;

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket);

        // User:
        this.socket.on('user', (dto: UserDto) => {
            this.userDto = dto;
            this.listeners.forEach(listener => listener('user', dto));
        });
        this.socket.on('login.success', (dto: LoginDto) => {
            this.userDto = dto.user;
            this.listeners.forEach(listener => listener('user', dto.user));
        });
        this.socket.on('register.success', (dto: LoginDto) => {
            this.userDto = dto.user;
            this.listeners.forEach(listener => listener('user', dto.user));
        });
    }

    /**
     * Gets the user state.
     * @returns The current user state.
     */
    public getUserState(): UserState
    {
        return {
            dto: this.userDto,
        };
    }

    /**
     * Clears the current user (used for logging out, etc).
     */
    public clearUser(): void
    {
        this.userDto = undefined;
        this.listeners.forEach(listener => listener('user', this.userDto));
    }
}